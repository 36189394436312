var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('date-tabs'),_c('b-card',[_c('b-row',{staticClass:"mb-1"},[_c('b-col',{attrs:{"lg":"3"}},[_c('label',[_vm._v("Person ID")]),_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"Номер документа"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function (val) {
									_vm.personID = val.target.value
									_vm.tableParams.page = 1
								}).apply(null, arguments)}},model:{value:(_vm.personID_),callback:function ($$v) {_vm.personID_=$$v},expression:"personID_"}}),(_vm.personID_)?_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"XIcon"},on:{"click":function () {
										_vm.personID_ = ''
										_vm.personID = ''
									}}})],1):_vm._e()],1)],1),_c('b-col',{attrs:{"lg":"3"}},[_c('label',[_vm._v("Наличие соответствий")]),_c('b-input-group',{staticStyle:{"flex-wrap":"nowrap"}},[_c('v-select',{staticClass:"w-100",attrs:{"placeholder":"Выберите статус","searchable":false,"options":[
								{ label: '-', value: undefined },
								{ label: 'Да', value: true },
								{ label: 'Нет', value: false } ],"clearable":false},model:{value:(_vm.isMatch),callback:function ($$v) {_vm.isMatch=$$v},expression:"isMatch"}}),(_vm.isMatch)?_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"XIcon"},on:{"click":function (val) {
										_vm.isMatch = undefined
									}}})],1):_vm._e()],1)],1)],1),_c('editable-table',{ref:"table",attrs:{"api":_vm.Oneton,"fields":_vm.fields,"params":_vm.tableParams,"on-click":_vm.onClick},scopedSlots:_vm._u([{key:"cell(created)",fn:function(data){return [_c('span',[_vm._v(" "+_vm._s(new Date(data.item.created).toLocaleDateString())+" ")]),_c('span',[_vm._v(" "+_vm._s((data.item.exec_time_ms / 1000).toFixed(2) + 'с')+" ")])]}},{key:"cell(threshold)",fn:function(data){return [_c('span',[_vm._v(" "+_vm._s(data.item.threshold)+"% ")])]}},{key:"cell(count)",fn:function(data){return [_c('span',[_vm._v(" "+_vm._s(data.item.response && data.item.response.data && data.item.response.data.results && data.item.response.data.results.length ? data.item.response.data.results.length : '-')+" ")])]}},{key:"cell(position)",fn:function(data){return [_c('span',[_vm._v(" "+_vm._s(data.item.response && data.item.response.data && data.item.response.data.results && data.item.response.data.results.length && data.item.response.data.results.find(function (r) { return r.personId == _vm.personID; }) ? data.item.response.data.results.indexOf( data.item.response.data.results.find(function (r) { return r.personId == _vm.personID; }) ) + 1 : '-')+" ")])]}},{key:"cell(probability)",fn:function(data){return [_c('span',[_vm._v(" "+_vm._s(data.item.response && data.item.response.data && data.item.response.data.results && data.item.response.data.results.length && data.item.response.data.results.find(function (r) { return r.personId == _vm.personID; }) ? data.item.response.data.results.find(function (r) { return r.personId == _vm.personID; }).recognitionValue : '-')+" ")])]}},{key:"cell(photo)",fn:function(data){return [_c('b-media',{attrs:{"vertical-align":"center"}},[[(data.item.photo)?_c('b-link',{attrs:{"href":_vm.filterMinioURL(data.item.photo.minio_path),"target":"_blank"}},[_c('b-avatar',{attrs:{"size":"32","src":_vm.filterMinioURL(
											data.item.photo.cropped_minio_path || data.item.photo.minio_path || ''
										),"variant":"light-primary"}})],1):_vm._e()]],2)]}}])})],1),_c('b-modal',{ref:"modal",attrs:{"id":"modal-lg","ok-only":"","ok-title":"Close","centered":"","size":"lg","title":"Информация"}},[_c('h3',[_vm._v("Фотография с камеры")]),_c('div',{staticClass:"employee-task d-flex justify-content-between align-items-center mb-2"},[(_vm.requestData)?_c('b-media',{attrs:{"no-body":""}},[_c('b-media-aside',{staticClass:"mr-75"},[_c('b-avatar',{attrs:{"rounded":"","size":"100","src":_vm.filterMinioURL(_vm.requestData.photo.minio_path)}})],1),_c('b-media-body',{staticClass:"my-auto"},[_c('h6',{staticClass:"mb-0"},[_c('b-badge',[_vm._v(" "+_vm._s(_vm.requestData.id)+" ")])],1),_c('div',[_vm._v(_vm._s(new Date(_vm.requestData.created).toLocaleString()))]),_c('div',[_vm._v(" "+_vm._s(_vm.requestData.response && _vm.requestData.response.data && _vm.requestData.response.data.results && _vm.requestData.response.data.results.length ? _vm.requestData.threshold + '%' : '-')+" ")])])],1):_vm._e()],1),_c('h3',[_vm._v("Результаты из базы фотографий 1/n")]),_vm._l((_vm.rowData),function(row){return _c('div',{key:row.personId,staticClass:"employee-task d-flex justify-content-between align-items-center mb-1"},[_c('b-media',{attrs:{"no-body":""}},[_c('b-media-aside',{staticClass:"mr-75"},[_c('b-link',{attrs:{"href":_vm.filterMinioURL(row.minio_path || ''),"target":"_blank"}},[_c('b-avatar',{attrs:{"rounded":"","size":"64","src":_vm.filterMinioURL(row.cropped_minio_path)}})],1)],1),_c('b-media-body',{staticClass:"my-auto"},[_c('h6',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(row.personId)+" ")]),_c('span',[_vm._v(_vm._s(row.recognitionValue)+"%")])])],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }